@import "bourbon/bourbon";

$primary-fonts: "Open Sans", sans-serif;
$heading-fonts: "Oswald", sans-serif;

$yellow: #f9e72a; //rgb(249,231,42);
$blue: #034f84; //rgb(3,79,132);
$orange: #ef841f; //rgb(239,132,31);
$blueblack: #2b2147; //rgb(43,33,71);
$warmgrey: #9896a4; //rgb(152,150,164);

$base-font-family: "Open Sans", sans-serif;
$base-font-size: 16px !default;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit: 30px !default;

$grey-color: #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark: darken($grey-color, 25%) !default;

// Width of the content area
$content-width: 800px !default;

$on-palm: 600px !default;
$on-laptop: 800px !default;

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

body {
  margin: 0;
  font-family: $primary-fonts;
  font-display: optional;
}

.left {
  text-align: left;
}

.nowrap {
  white-space: nowrap;
}

p {
  margin: 0;
  line-height: 1.5em;
}

.post-content, .post-content p {
  line-height: 2em;
}
.post-content pre {
  line-height: 1.5em;
}

h1,
h2,
h3,
h4,
h6 {
  margin: 0;
  font-family: $heading-fonts;
  font-display: optional;
}
h5 {
  margin: 0;
  font-family: $heading-fonts;
  font-display: optional;
  font-size: large;
}

a {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}

.navbar {
  position: relative;
  background: $blue;
  vertical-align: middle;
  padding: 1em;
  color: #fff;
  max-width: 100%;
  min-height: 66px;
  overflow: auto;
  text-align: center;
}

.navbar img, .post-content img {
  vertical-align: middle;
  margin: 0 auto;
  max-width: 100%;
}
.navlinks {
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
  max-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.navlinks li {
  display: inline-block;
  margin-left: auto;
  vertical-align: middle;
}
.navlinks li a {
  display: block;
  padding: 1em;
}
.logo a {
  display: inline-block;
  border-bottom: 1px solid transparent;
}
.logo a:hover {
  border-bottom: 1px solid $orange;
}
.navlinks a:hover,
.sitemap-card a:hover {
  background: $orange;
}
.tellmemore p {
  color: #333;
}
.tellmemore .cta a {
  border: 1px solid #000;
  padding: 1em;
  display: inline-block;
}
.allabout {
  max-width: 30em;
  display: inline-block;
  vertical-align: top;
}


.middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.centerbig {
  max-width: 1050px;
  margin: 0 auto;
}
footer {
  border-top: 2px solid $orange;
  background: $blue;
  color: #fff;
  padding: 2em;
  vertical-align: top;
}
.copyright {
  text-align: center;
  margin-top: 1em;
}
.sitemap-card {
  vertical-align: top;
  margin-bottom: 1em;
}
.sitemap-card li,
.sitemap-card div {
  display: block;
  margin: 1em 0;
}
.sitemap-card li a {
  font-weight: normal;
}
.sitemap-card ul {
  margin: 0;
  padding: 0;
}
.sitemap-card-heading {
  border-bottom: 1px solid $orange;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
}

.products,
.landingcards {
  display: flex;
  flex-wrap: wrap;
  background: #f9f9f9;
}
.product-card,
.landing-card {
  flex-grow: 1;
  flex-basis: 80%;
}
.product-image img,
.landing-info img {
  max-width: 100%;
}
.product-info {
  margin-top: auto;
  padding: 5%;
}
.landing-info {
  margin-top: auto;
  padding: 0 5%;
}

.product-info p,
.landing-info p {
  color: #555;
}

.product-image img {
  width: 100%;
}

.landing-image img {
  padding: 5% 0 0 5%;
  width: 20%;
}

.capabilities-image img {
  width: 100%;
}

.capabilities-info-body {
  text-align: center;
  padding: 2em;
  color: #000;
}

.capabilities-info-body p {
  color: #555;
  text-align: justify;
}

.louisville {
  border-bottom: 1px solid $orange;
  background-color: $blue;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 1em;
  vertical-align: middle;
  text-align: center;
}
.louisville .cta a {
  display: inline-block;
  padding: 1em;
  border: 1px solid #fff;
  margin-top: 1em;
}

@media only screen and (min-width: 600px) {
  /* The header goes tall right around this time, need the large picture */
  .louisville {
    padding: 5em;
  }
}

.no-webp .louisville {
  background-image: url("/assets/images/generated/about/louisville-skyline-320-5cf510c09.jpg");
}
.webp .louisville {
  background-image: url("/assets/images/generated/about/louisville-skyline-320-471281e92.webp");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .no-webp .louisville {
    background-image: url("/assets/images/generated/about/louisville-skyline-640-5cf510c09.jpg");
  }
  .webp .louisville {
    background-image: url("/assets/images/generated/about/louisville-skyline-640-471281e92.webp");
  }
}

@media only screen and (min-width: 600px) {
  /* header goes tall at 600px */
  .no-webp .louisville {
    background-image: url("/assets/images/generated/about/louisville-skyline-1280-5cf510c09.jpg");
  }
  .webp .louisville {
    background-image: url("/assets/images/generated/about/louisville-skyline-1280-471281e92.webp");
  }
}

@media only screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-width: 1200px) and (min-resolution: 192dpi),
  only screen and (min-width: 2400px) {
  .no-webp .louisville {
    background-image: url("/assets/images/generated/about/louisville-skyline-1903-5cf510c09.jpg");
  }
  .webp .louisville {
    background-image: url("/assets/images/generated/about/louisville-skyline-1903-471281e92.webp");
  }
}

.hero {
  padding: 1em;
}

.center {
  text-align: center;
  margin-left: auto; margin-right: auto;
}

.contact .centerbig {
  text-align: center;
  padding: 1em;
}

.contact .centerbig div {
  margin: 1em;
}

.contact a {
  font-size: smaller;
  color: $blue;
  white-space: nowrap;
}

.map iframe {
  border: 0;
  width: 100%;
  height: 20em;
}

.smallstack {
  display: block;
}

.landinghero {
  background-color: #004183;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  border-top: 2px solid $orange;
  border-bottom: 2px solid $orange;
  color: #fff;
  padding: 1em;
  font-size: large;
  text-shadow: 0 0 10px #000, 0 0 10px #000;
}

.landingshort {
  background-color: $warmgrey;
  background-position: 80%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ddd;
  padding: 1em;
  vertical-align: middle;
  font-weight: bold;
  text-align: center;
  font-size: medium;
}

article .cta { text-align: center; margin-bottom: 2em; }
.landingshort .cta a, article .cta a, article .cta a:visited {
  margin-top: 1em;
  background: $orange;
  color: #fff;
  display: inline-block;
  text-shadow: none;
  border-radius: 5px;
  font-size: x-large;
  font-weight: normal;
  font-family: $heading-fonts;
  font-display: optional;
}
.landingshort .cta a {
  padding: 1em;
}
article .cta a, article .cta a:visited {
  padding: 0.5em 1em;
  text-decoration: none;
}

.landingcapabilities {
  background-color: $orange;
  color: #000;
  padding: 1em;
  vertical-align: middle;
  font-weight: bold;
  text-align: center;
  font-size: medium;
  /*text-shadow: 0 0 10px #000, 0 0 10px #000;*/
}

.landingcontact h1 {
  text-align: center;
}

input,
button,
textarea {
  box-sizing: border-box;
  font-size: 16pt;
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.5em;
  width: 100%;
  font-family: $primary-fonts;
  font-display: optional;
  margin-bottom: 0.5em;
}
button[type="submit"] {
  appearance: none;
  background-color: $orange;
  color: #fff;
  font-family: $heading-fonts;
  font-display: optional;
  margin: 1em 0;
  border: 1px solid $orange;
  border-radius: 5px;
}

button[type="submit"].active {
  background-color: #ccc;
  border-color: #ccc;
  color: #555;
}
button[type="submit"].active span {
  display: none;
}
button[type="submit"].finished {
  animation: fadeout 0.2s ease-in-out;
  background-color: #fff;
  border-color: #fff;
}
@keyframes fadeout {
  0% {
    background-color: #ccc;
    border-color: #ccc;
  }
  100% {
    background-color: #fff;
    border-color: #fff;
  }
}
button[type="submit"].finished span {
  display: none;
}
button[type="submit"].invalid {
  animation: nope 0.35s ease-in-out;
}

@keyframes nope {
  25%,
  65%,
  94% {
    transform: translateX(30px);
  }
  45%,
  82% {
    transform: translateX(-30px);
  }
  0%,
  35%,
  55%,
  75%,
  87%,
  97%,
  100% {
    transform: translateX(0px);
  }
}
.form {
  padding: 0 5%;
}

$circle-size: 64px;

.loader {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: 2px solid $yellow;
  border-radius: 50%;
  border-top: 2px solid #555;
  width: $circle-size - 7px;
  height: $circle-size - 7px;
  animation: spin 1s ease-out infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

$check-height: $circle-size/2;
$check-width: $check-height/2;
$check-left: $circle-size/6;
$check-thickness: $circle-size/10;

.done {
  width: $circle-size;
  height: $circle-size;
  position: relative;
  display: inline-block;
  vertical-align: top;

  .background {
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    background: #2eb150;
    position: absolute;
  }

  .checkmark {
    &.draw:after {
      /*animation-delay: 100ms;*/
      animation-duration: 1s;
      animation-timing-function: ease-out;
      animation-name: checkmark;
      transform: scaleX(-1) rotate(180deg + -45deg);
      animation-fill-mode: forwards;
    }

    border-radius: 5px;

    &:after {
      opacity: 1;
      height: $check-height;
      width: $check-width;
      transform-origin: left top;
      border-right: $check-thickness solid #fff;
      border-top: $check-thickness solid #fff;
      border-radius: 2.5px !important;
      content: "";
      left: $check-left;
      top: $check-height;
      position: absolute;
    }
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}

.formfooter, .credits {
  text-align: center;
  color: #aaa;
  font-size: 10pt;
}

.formfooter.active {
  animation: hint 2s ease 1;
}

@keyframes hint {
  50% {
    color: #000;
    background-color: $yellow;
  }
}

@media only screen and (min-width: 400px) {
  /* For medium phone: */
  .hero .smallstack {
    display: inline;
  }

  .contact a {
    font-size: inherit;
  }
}

@media only screen and (min-width: 421px) {
  /* For medium phone: */

  .landinghero .smallstack {
    display: inline;
  }
}

@media (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (min-width: 600px) {
  /* For tablet: */
  .contact a {
    padding: 1em;
  }
  .hero,
  .tellmemore {
    padding: 5em;
  }
  .product-card,
  .landing-card {
    flex: 1 40%;
    margin: 0.5em;
  }

  .sitemap-card {
    width: 44%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    display: inline-block;
  }
  .allabout {
    width: 50%;
  }
  input {
    font-size: 18pt;
  }
  .landinghero {
    font-size: x-large;
  }
  .form {
    width: 50%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 850px) {
  .navbar .logo {
    float: left;
  }
  .navlinks {
    float: right;
    margin: 0;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .hero,
  .louisville {
    height: 300px;
  }
  .hero .centerbig,
  .louisville .centerbig,
  .landinghero .centerbig,
  .landingshort .centerbig,
  .landingcapabilities .centerbig,
  .landingcontact .centerbig {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .capabilities-card {
    clear: both;
  }
  .capabilities-image {
    display: inline-block;
    max-width: 40%;
    vertical-align: middle;
    position: inherit;
  }
  .capabilities-card {
    background: linear-gradient(to right, #fff 70%, #e4e4e4 100%);
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .capabilities-info {
    flex: 1 1 60%;
  }
  .capabilities-info-body {
    background: inherit;
    text-align: left;
    text-shadow: none;
    padding: 1em;
    color: #000;
  }
  .capabilities-info-body p {
    color: #555;
  }
  .capabilities-image {
    flex: 1 1 40%;
  }
  .capabilities-card:nth-child(even) {
    background: linear-gradient(to left, #fff 70%, #e4e4e4 100%);
  }
  .capabilities-card:nth-child(even) .capabilities-info {
    order: 1;
  }
  .capabilities-card:nth-child(even) .capabilities-image {
    order: 2;
  }

  .sitemap-card {
    width: 19%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    display: inline-block;
  }

  .contact-left,
  .contact-right {
    width: 45%;
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: top;
  }

  .landinghero,
  .landingshort,
  .landingcapabilities,
  .landingcontact {
    /* since we're setting to viewport height percentage, remove padding */
    padding: 0;
    margin: 0;
  }
  .landinghero {
    height: 66vh;
    font-size: xx-large;
  }
  .landingcapabilities,
  .landingshort {
    height: 34vh;
  }
  .landingcontact {
    height: 100vh;
  }
}

@media only screen and (min-width: 850px) {
  .product-card {
    flex: 1 30%;
  }
}

@media only screen and (min-width: 1100px) {
  /* For big desktop: */
  .product-card {
    flex: 1 20%;
  }
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit / 2;
}

/**
 * Syntax highlighting styles
 */
code.highlighter-rouge {
  background-color: lighten($warmgrey, 35%);
  padding: 0.5em;
  color: #d14;
  white-space: nowrap;
}
.highlight {
  background: #fff;
  @extend %vertical-rhythm;

  .highlighter-rouge & {
    background-color: lighten($warmgrey, 35%);
    padding: 0.5em;
    margin: 0;
    overflow: auto;
    max-width: 100%;
  }

  .c {
    color: #998;
    font-style: italic;
  } // Comment
  .err {
    color: #a61717;
    background-color: #e3d2d2;
  } // Error
  .k {
    font-weight: bold;
  } // Keyword
  .o {
    font-weight: bold;
  } // Operator
  .cm {
    color: #998;
    font-style: italic;
  } // Comment.Multiline
  .cp {
    color: #999;
    font-weight: bold;
  } // Comment.Preproc
  .c1 {
    color: #998;
    font-style: italic;
  } // Comment.Single
  .cs {
    color: #999;
    font-weight: bold;
    font-style: italic;
  } // Comment.Special
  .gd {
    color: #000;
    background-color: #fdd;
  } // Generic.Deleted
  .gd .x {
    color: #000;
    background-color: #faa;
  } // Generic.Deleted.Specific
  .ge {
    font-style: italic;
  } // Generic.Emph
  .gr {
    color: #a00;
  } // Generic.Error
  .gh {
    color: #999;
  } // Generic.Heading
  .gi {
    color: #000;
    background-color: #dfd;
  } // Generic.Inserted
  .gi .x {
    color: #000;
    background-color: #afa;
  } // Generic.Inserted.Specific
  .go {
    color: #888;
  } // Generic.Output
  .gp {
    color: #555;
  } // Generic.Prompt
  .gs {
    font-weight: bold;
  } // Generic.Strong
  .gu {
    color: #aaa;
  } // Generic.Subheading
  .gt {
    color: #a00;
  } // Generic.Traceback
  .kc {
    font-weight: bold;
  } // Keyword.Constant
  .kd {
    font-weight: bold;
  } // Keyword.Declaration
  .kp {
    font-weight: bold;
  } // Keyword.Pseudo
  .kr {
    font-weight: bold;
  } // Keyword.Reserved
  .kt {
    color: #458;
    font-weight: bold;
  } // Keyword.Type
  .m {
    color: #099;
  } // Literal.Number
  .s {
    color: #d14;
  } // Literal.String
  .na {
    color: #008080;
  } // Name.Attribute
  .nb {
    color: #0086b3;
  } // Name.Builtin
  .nc {
    color: #458;
    font-weight: bold;
  } // Name.Class
  .no {
    color: #008080;
  } // Name.Constant
  .ni {
    color: #800080;
  } // Name.Entity
  .ne {
    color: #900;
    font-weight: bold;
  } // Name.Exception
  .nf {
    color: #900;
    font-weight: bold;
  } // Name.Function
  .nn {
    color: #555;
  } // Name.Namespace
  .nt {
    color: #000080;
  } // Name.Tag
  .nv {
    color: #008080;
  } // Name.Variable
  .ow {
    font-weight: bold;
  } // Operator.Word
  .w {
    color: #bbb;
  } // Text.Whitespace
  .mf {
    color: #099;
  } // Literal.Number.Float
  .mh {
    color: #099;
  } // Literal.Number.Hex
  .mi {
    color: #099;
  } // Literal.Number.Integer
  .mo {
    color: #099;
  } // Literal.Number.Oct
  .sb {
    color: #d14;
  } // Literal.String.Backtick
  .sc {
    color: #d14;
  } // Literal.String.Char
  .sd {
    color: #d14;
  } // Literal.String.Doc
  .s2 {
    color: #d14;
  } // Literal.String.Double
  .se {
    color: #d14;
  } // Literal.String.Escape
  .sh {
    color: #d14;
  } // Literal.String.Heredoc
  .si {
    color: #d14;
  } // Literal.String.Interpol
  .sx {
    color: #d14;
  } // Literal.String.Other
  .sr {
    color: #009926;
  } // Literal.String.Regex
  .s1 {
    color: #d14;
  } // Literal.String.Single
  .ss {
    color: #990073;
  } // Literal.String.Symbol
  .bp {
    color: #999;
  } // Name.Builtin.Pseudo
  .vc {
    color: #008080;
  } // Name.Variable.Class
  .vg {
    color: #008080;
  } // Name.Variable.Global
  .vi {
    color: #008080;
  } // Name.Variable.Instance
  .il {
    color: #099;
  } // Literal.Number.Integer.Long
}

/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.centerbig {
  > h2.post-list-heading, > ul.post-list, > p {
    margin: 2.5%;
  }
}
div#disqus_thread {
  margin: 2.5%;
}
.post-list {
  margin-left: 0;
  padding: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta, .photo-credit {
  font-size: $small-font-size;
  color: $grey-color;
}
div.post-meta {
  margin-left: 2.5%;
}

@media screen and (min-width: 500px) {
  div.post-meta, div.photo-credit {
    display:inline-block;
    width:47.5%;
  }
  div.photo-credit {
    margin-right: 2.5%;
    text-align: right;
  }
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}

.post a {
  text-decoration: underline;
  color: $blue;
  text-underline-position: under;
}
.post a:visited {
  color: lighten($blueblack, 40%);
}

/**
   * Posts
   */

.post-header {
  .centerbig {
    h1, p {
      margin-left: 2.5%;
      margin-right: 2.5%;
    }
  }

  &.dark-bg {
    color: #fff;
  }
}

.post-header-cover {
  padding-top: 2.5%;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin: 2.5%;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}

.post-list .cta a {
  margin-top: 1em;
  border: 1px solid $warmgrey;
  color: $blueblack;
  padding: 0.5em;
  display: inline-block;
  text-shadow: none;
  border-radius: 5px;
  font-size: large;
  font-weight: normal;
  font-family: $heading-fonts;
  font-display: optional;
}

.post-list {
  .video-container, .ytplayer {
    display: block;
    float: right;
    margin:0 auto!important;
    min-width:320px;
    max-width:50%;
    height:auto;
    min-height:180px;
    overflow:hidden;
    padding:0!important;
    border:none;
    outline:none;
  }
}


.post-content {
  .video-container, .ytplayer {
    margin:0 auto!important;
    width:100%;
    max-width:100%;
    height:auto;
    min-height:350px;
    display:block;
    overflow:hidden;
    padding:0!important;
    border:none;
    outline:none;
  }
}

.post-description {
  color: $grey-color-dark;
}
.dark-bg .post-description {
  color: $grey-color-light;
}
.post-header.dark-bg .post-header-cover {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0);
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.53) 53%, rgba(0,0,0,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(53%, rgba(0,0,0,0.53)), color-stop(100%, rgba(0,0,0,1)));
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.53) 53%, rgba(0,0,0,1) 100%);
  background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.53) 53%, rgba(0,0,0,1) 100%);
  background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.53) 53%, rgba(0,0,0,1) 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.53) 53%, rgba(0,0,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
}







/*
==============================================
CSS3 ANIMATION CHEAT SHEET
==============================================

Made by Justin Aguilar

www.justinaguilar.com/animations/

Questions, comments, concerns, love letters:
justin@justinaguilar.com
==============================================
*/

/*
==============================================
slideDown
==============================================
*/


.slideDown{
	animation-name: slideDown;
	-webkit-animation-name: slideDown;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;	

	visibility: visible !important;						
}

@keyframes slideDown {
	0% {
		transform: translateY(-100%);
	}
	50%{
		transform: translateY(8%);
	}
	65%{
		transform: translateY(-4%);
	}
	80%{
		transform: translateY(4%);
	}
	95%{
		transform: translateY(-2%);
	}			
	100% {
		transform: translateY(0%);
	}		
}

@-webkit-keyframes slideDown {
	0% {
		-webkit-transform: translateY(-100%);
	}
	50%{
		-webkit-transform: translateY(8%);
	}
	65%{
		-webkit-transform: translateY(-4%);
	}
	80%{
		-webkit-transform: translateY(4%);
	}
	95%{
		-webkit-transform: translateY(-2%);
	}			
	100% {
		-webkit-transform: translateY(0%);
	}	
}

/*
==============================================
slideUp
==============================================
*/


.slideUp{
	animation-name: slideUp;
	-webkit-animation-name: slideUp;	

	animation-duration: 0.2s;	
	-webkit-animation-duration: 0.2s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;

	visibility: visible !important;			
}

@keyframes slideUp {
	0% {
		transform: translateY(100%);
	}
/*
	50%{
		transform: translateY(-8%);
	}
	65%{
		transform: translateY(4%);
	}
	80%{
		transform: translateY(-4%);
	}
	95%{
		transform: translateY(2%);
	}
*/
	100% {
		transform: translateY(0%);
	}	
}

@-webkit-keyframes slideUp {
	0% {
		-webkit-transform: translateY(100%);
	}
/*
	50%{
		-webkit-transform: translateY(-8%);
	}
	65%{
		-webkit-transform: translateY(4%);
	}
	80%{
		-webkit-transform: translateY(-4%);
	}
	95%{
		-webkit-transform: translateY(2%);
	}			
*/
	100% {
		-webkit-transform: translateY(0%);
	}	
}

/*
==============================================
slideLeft
==============================================
*/


.slideLeft{
	animation-name: slideLeft;
	-webkit-animation-name: slideLeft;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;		

	visibility: visible !important;	
}

@keyframes slideLeft {
	0% {
		transform: translateX(150%);
	}
	50%{
		transform: translateX(-8%);
	}
	65%{
		transform: translateX(4%);
	}
	80%{
		transform: translateX(-4%);
	}
	95%{
		transform: translateX(2%);
	}			
	100% {
		transform: translateX(0%);
	}
}

@-webkit-keyframes slideLeft {
	0% {
		-webkit-transform: translateX(150%);
	}
	50%{
		-webkit-transform: translateX(-8%);
	}
	65%{
		-webkit-transform: translateX(4%);
	}
	80%{
		-webkit-transform: translateX(-4%);
	}
	95%{
		-webkit-transform: translateX(2%);
	}			
	100% {
		-webkit-transform: translateX(0%);
	}
}

/*
==============================================
slideRight
==============================================
*/


.slideRight{
	animation-name: slideRight;
	-webkit-animation-name: slideRight;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;		

	visibility: visible !important;	
}

@keyframes slideRight {
	0% {
		transform: translateX(-150%);
	}
	50%{
		transform: translateX(8%);
	}
	65%{
		transform: translateX(-4%);
	}
	80%{
		transform: translateX(4%);
	}
	95%{
		transform: translateX(-2%);
	}			
	100% {
		transform: translateX(0%);
	}	
}

@-webkit-keyframes slideRight {
	0% {
		-webkit-transform: translateX(-150%);
	}
	50%{
		-webkit-transform: translateX(8%);
	}
	65%{
		-webkit-transform: translateX(-4%);
	}
	80%{
		-webkit-transform: translateX(4%);
	}
	95%{
		-webkit-transform: translateX(-2%);
	}			
	100% {
		-webkit-transform: translateX(0%);
	}
}

/*
==============================================
slideExpandUp
==============================================
*/


.slideExpandUp{
	animation-name: slideExpandUp;
	-webkit-animation-name: slideExpandUp;	

	animation-duration: 1.6s;	
	-webkit-animation-duration: 1.6s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease -out;

	visibility: visible !important;	
}

@keyframes slideExpandUp {
	0% {
		transform: translateY(100%) scaleX(0.5);
	}
	30%{
		transform: translateY(-8%) scaleX(0.5);
	}	
	40%{
		transform: translateY(2%) scaleX(0.5);
	}
	50%{
		transform: translateY(0%) scaleX(1.1);
	}
	60%{
		transform: translateY(0%) scaleX(0.9);		
	}
	70% {
		transform: translateY(0%) scaleX(1.05);
	}			
	80%{
		transform: translateY(0%) scaleX(0.95);		
	}
	90% {
		transform: translateY(0%) scaleX(1.02);
	}	
	100%{
		transform: translateY(0%) scaleX(1);		
	}
}

@-webkit-keyframes slideExpandUp {
	0% {
		-webkit-transform: translateY(100%) scaleX(0.5);
	}
	30%{
		-webkit-transform: translateY(-8%) scaleX(0.5);
	}	
	40%{
		-webkit-transform: translateY(2%) scaleX(0.5);
	}
	50%{
		-webkit-transform: translateY(0%) scaleX(1.1);
	}
	60%{
		-webkit-transform: translateY(0%) scaleX(0.9);		
	}
	70% {
		-webkit-transform: translateY(0%) scaleX(1.05);
	}			
	80%{
		-webkit-transform: translateY(0%) scaleX(0.95);		
	}
	90% {
		-webkit-transform: translateY(0%) scaleX(1.02);
	}	
	100%{
		-webkit-transform: translateY(0%) scaleX(1);		
	}
}

/*
==============================================
expandUp
==============================================
*/


.expandUp{
	animation-name: expandUp;
	-webkit-animation-name: expandUp;	

	animation-duration: 0.7s;	
	-webkit-animation-duration: 0.7s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;		

	visibility: visible !important;	
}

@keyframes expandUp {
	0% {
		transform: translateY(100%) scale(0.6) scaleY(0.5);
	}
	60%{
		transform: translateY(-7%) scaleY(1.12);
	}
	75%{
		transform: translateY(3%);
	}	
	100% {
		transform: translateY(0%) scale(1) scaleY(1);
	}	
}

@-webkit-keyframes expandUp {
	0% {
		-webkit-transform: translateY(100%) scale(0.6) scaleY(0.5);
	}
	60%{
		-webkit-transform: translateY(-7%) scaleY(1.12);
	}
	75%{
		-webkit-transform: translateY(3%);
	}	
	100% {
		-webkit-transform: translateY(0%) scale(1) scaleY(1);
	}	
}

/*
==============================================
fadeIn
==============================================
*/

.fadeIn{
	animation-name: fadeIn;
	-webkit-animation-name: fadeIn;	

	animation-duration: 0.2s;	
	-webkit-animation-duration: 0.2s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;		

	visibility: visible !important;	
}

@keyframes fadeIn {
	0% {
		transform: scale(0);
		opacity: 0.0;		
	}
/*
	60% {
		transform: scale(1.1);	
	}
	80% {
		transform: scale(0.9);
		opacity: 1;	
	}	
*/
	100% {
		transform: scale(1);
		opacity: 1;	
	}		
}

@-webkit-keyframes fadeIn {
	0% {
		-webkit-transform: scale(0);
		opacity: 0.0;		
	}
/*
	60% {
		-webkit-transform: scale(1.1);
	}
	80% {
		-webkit-transform: scale(0.9);
		opacity: 1;	
	}	
*/
	100% {
		-webkit-transform: scale(1);
		opacity: 1;	
	}		
}

/*
==============================================
expandOpen
==============================================
*/


.expandOpen{
	animation-name: expandOpen;
	-webkit-animation-name: expandOpen;	

	animation-duration: 1.2s;	
	-webkit-animation-duration: 1.2s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	visibility: visible !important;	
}

@keyframes expandOpen {
	0% {
		transform: scale(1.8);		
	}
	50% {
		transform: scale(0.95);
	}	
	80% {
		transform: scale(1.05);
	}
	90% {
		transform: scale(0.98);
	}	
	100% {
		transform: scale(1);
	}			
}

@-webkit-keyframes expandOpen {
	0% {
		-webkit-transform: scale(1.8);		
	}
	50% {
		-webkit-transform: scale(0.95);
	}	
	80% {
		-webkit-transform: scale(1.05);
	}
	90% {
		-webkit-transform: scale(0.98);
	}	
	100% {
		-webkit-transform: scale(1);
	}					
}

/*
==============================================
bigEntrance
==============================================
*/


.bigEntrance{
	animation-name: bigEntrance;
	-webkit-animation-name: bigEntrance;	

	animation-duration: 1.6s;	
	-webkit-animation-duration: 1.6s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	visibility: visible !important;			
}

@keyframes bigEntrance {
	0% {
		transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
		opacity: 0.2;
	}
	30% {
		transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);		
		opacity: 1;
	}
	45% {
		transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	60% {
		transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	75% {
		transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	90% {
		transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	100% {
		transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
		opacity: 1;
	}		
}

@-webkit-keyframes bigEntrance {
	0% {
		-webkit-transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
		opacity: 0.2;
	}
	30% {
		-webkit-transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);		
		opacity: 1;
	}
	45% {
		-webkit-transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	60% {
		-webkit-transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	75% {
		-webkit-transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	90% {
		-webkit-transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	100% {
		-webkit-transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
		opacity: 1;
	}				
}

/*
==============================================
hatch
==============================================
*/

.hatch{
	animation-name: hatch;
	-webkit-animation-name: hatch;	

	animation-duration: 2s;	
	-webkit-animation-duration: 2s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;

	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%; 

	visibility: visible !important;		
}

@keyframes hatch {
	0% {
		transform: rotate(0deg) scaleY(0.6);
	}
	20% {
		transform: rotate(-2deg) scaleY(1.05);
	}
	35% {
		transform: rotate(2deg) scaleY(1);
	}
	50% {
		transform: rotate(-2deg);
	}	
	65% {
		transform: rotate(1deg);
	}	
	80% {
		transform: rotate(-1deg);
	}		
	100% {
		transform: rotate(0deg);
	}									
}

@-webkit-keyframes hatch {
	0% {
		-webkit-transform: rotate(0deg) scaleY(0.6);
	}
	20% {
		-webkit-transform: rotate(-2deg) scaleY(1.05);
	}
	35% {
		-webkit-transform: rotate(2deg) scaleY(1);
	}
	50% {
		-webkit-transform: rotate(-2deg);
	}	
	65% {
		-webkit-transform: rotate(1deg);
	}	
	80% {
		-webkit-transform: rotate(-1deg);
	}		
	100% {
		-webkit-transform: rotate(0deg);
	}		
}


/*
==============================================
bounce
==============================================
*/


.bounce{
	animation-name: bounce;
	-webkit-animation-name: bounce;	

	animation-duration: 1.6s;	
	-webkit-animation-duration: 1.6s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;	
	
	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%; 	
}

@keyframes bounce {
	0% {
		transform: translateY(0%) scaleY(0.6);
	}
	60%{
		transform: translateY(-100%) scaleY(1.1);
	}
	70%{
		transform: translateY(0%) scaleY(0.95) scaleX(1.05);
	}
	80%{
		transform: translateY(0%) scaleY(1.05) scaleX(1);
	}	
	90%{
		transform: translateY(0%) scaleY(0.95) scaleX(1);
	}				
	100%{
		transform: translateY(0%) scaleY(1) scaleX(1);
	}	
}

@-webkit-keyframes bounce {
	0% {
		-webkit-transform: translateY(0%) scaleY(0.6);
	}
	60%{
		-webkit-transform: translateY(-100%) scaleY(1.1);
	}
	70%{
		-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1.05);
	}
	80%{
		-webkit-transform: translateY(0%) scaleY(1.05) scaleX(1);
	}	
	90%{
		-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1);
	}				
	100%{
		-webkit-transform: translateY(0%) scaleY(1) scaleX(1);
	}		
}


/*
==============================================
pulse
==============================================
*/

.pulse{
	animation-name: pulse;
	-webkit-animation-name: pulse;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.9);
		opacity: 0.7;		
	}
	50% {
		transform: scale(1);
		opacity: 1;	
	}	
	100% {
		transform: scale(0.9);
		opacity: 0.7;	
	}			
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(0.95);
		opacity: 0.7;		
	}
	50% {
		-webkit-transform: scale(1);
		opacity: 1;	
	}	
	100% {
		-webkit-transform: scale(0.95);
		opacity: 0.7;	
	}			
}

/*
==============================================
floating
==============================================
*/

.floating{
	animation-name: floating;
	-webkit-animation-name: floating;

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes floating {
	0% {
		transform: translateY(0%);	
	}
	50% {
		transform: translateY(8%);	
	}	
	100% {
		transform: translateY(0%);
	}			
}

@-webkit-keyframes floating {
	0% {
		-webkit-transform: translateY(0%);	
	}
	50% {
		-webkit-transform: translateY(8%);	
	}	
	100% {
		-webkit-transform: translateY(0%);
	}			
}

/*
==============================================
tossing
==============================================
*/

.tossing{
	animation-name: tossing;
	-webkit-animation-name: tossing;	

	animation-duration: 2.5s;	
	-webkit-animation-duration: 2.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes tossing {
	0% {
		transform: rotate(-4deg);	
	}
	50% {
		transform: rotate(4deg);
	}
	100% {
		transform: rotate(-4deg);	
	}						
}

@-webkit-keyframes tossing {
	0% {
		-webkit-transform: rotate(-4deg);	
	}
	50% {
		-webkit-transform: rotate(4deg);
	}
	100% {
		-webkit-transform: rotate(-4deg);	
	}				
}

/*
==============================================
pullUp
==============================================
*/

.pullUp{
	animation-name: pullUp;
	-webkit-animation-name: pullUp;	

	animation-duration: 1.1s;	
	-webkit-animation-duration: 1.1s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%; 		
}

@keyframes pullUp {
	0% {
		transform: scaleY(0.1);
	}
	40% {
		transform: scaleY(1.02);
	}
	60% {
		transform: scaleY(0.98);
	}
	80% {
		transform: scaleY(1.01);
	}
	100% {
		transform: scaleY(0.98);
	}				
	80% {
		transform: scaleY(1.01);
	}
	100% {
		transform: scaleY(1);
	}							
}

@-webkit-keyframes pullUp {
	0% {
		-webkit-transform: scaleY(0.1);
	}
	40% {
		-webkit-transform: scaleY(1.02);
	}
	60% {
		-webkit-transform: scaleY(0.98);
	}
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(0.98);
	}				
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(1);
	}		
}

/*
==============================================
pullDown
==============================================
*/

.pullDown{
	animation-name: pullDown;
	-webkit-animation-name: pullDown;	

	animation-duration: 1.1s;	
	-webkit-animation-duration: 1.1s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 50% 0%;
	-ms-transform-origin: 50% 0%;
	-webkit-transform-origin: 50% 0%; 		
}

@keyframes pullDown {
	0% {
		transform: scaleY(0.1);
	}
	40% {
		transform: scaleY(1.02);
	}
	60% {
		transform: scaleY(0.98);
	}
	80% {
		transform: scaleY(1.01);
	}
	100% {
		transform: scaleY(0.98);
	}				
	80% {
		transform: scaleY(1.01);
	}
	100% {
		transform: scaleY(1);
	}							
}

@-webkit-keyframes pullDown {
	0% {
		-webkit-transform: scaleY(0.1);
	}
	40% {
		-webkit-transform: scaleY(1.02);
	}
	60% {
		-webkit-transform: scaleY(0.98);
	}
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(0.98);
	}				
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(1);
	}		
}

/*
==============================================
stretchLeft
==============================================
*/

.stretchLeft{
	animation-name: stretchLeft;
	-webkit-animation-name: stretchLeft;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 100% 0%;
	-ms-transform-origin: 100% 0%;
	-webkit-transform-origin: 100% 0%; 
}

@keyframes stretchLeft {
	0% {
		transform: scaleX(0.3);
	}
	40% {
		transform: scaleX(1.02);
	}
	60% {
		transform: scaleX(0.98);
	}
	80% {
		transform: scaleX(1.01);
	}
	100% {
		transform: scaleX(0.98);
	}				
	80% {
		transform: scaleX(1.01);
	}
	100% {
		transform: scaleX(1);
	}							
}

@-webkit-keyframes stretchLeft {
	0% {
		-webkit-transform: scaleX(0.3);
	}
	40% {
		-webkit-transform: scaleX(1.02);
	}
	60% {
		-webkit-transform: scaleX(0.98);
	}
	80% {
		-webkit-transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(0.98);
	}				
	80% {
		-webkit-transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(1);
	}		
}

/*
==============================================
stretchRight
==============================================
*/

.stretchRight{
	animation-name: stretchRight;
	-webkit-animation-name: stretchRight;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 0% 0%;
	-ms-transform-origin: 0% 0%;
	-webkit-transform-origin: 0% 0%; 		
}

@keyframes stretchRight {
	0% {
		transform: scaleX(0.3);
	}
	40% {
		transform: scaleX(1.02);
	}
	60% {
		transform: scaleX(0.98);
	}
	80% {
		transform: scaleX(1.01);
	}
	100% {
		transform: scaleX(0.98);
	}				
	80% {
		transform: scaleX(1.01);
	}
	100% {
		transform: scaleX(1);
	}							
}

@-webkit-keyframes stretchRight {
	0% {
		-webkit-transform: scaleX(0.3);
	}
	40% {
		-webkit-transform: scaleX(1.02);
	}
	60% {
		-webkit-transform: scaleX(0.98);
	}
	80% {
		-webkit-transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(0.98);
	}				
	80% {
		-webkit-transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(1);
	}		
}




/* dwf: fadeInSlideUp */



.fadeInSlideUp{
	animation-name: fadeInSlideUp;
	-webkit-animation-name: fadeInSlideUp;	

	animation-duration: 0.5s;	
	-webkit-animation-duration: 0.5s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;		

	visibility: visible !important;	
}

@keyframes fadeInSlideUp {
	0% {
		transform: scale(0);
		opacity: 0.0;		
		transform: translateY(100%);
	}
/*
	60% {
		transform: scale(1.1);	
	}
	80% {
		transform: scale(0.9);
		opacity: 1;	
	}	
*/
	100% {
		transform: scale(1);
		opacity: 1;	
		transform: translateY(0%);
	}		
}

@-webkit-keyframes fadeInSlideUp {
	0% {
		-webkit-transform: scale(0);
		opacity: 0.0;		
		-webkit-transform: translateY(100%);
	}
/*
	60% {
		-webkit-transform: scale(1.1);
	}
	80% {
		-webkit-transform: scale(0.9);
		opacity: 1;	
	}	
*/
	100% {
		-webkit-transform: scale(1);
		opacity: 1;	
		-webkit-transform: translateY(0%);
	}		
}






